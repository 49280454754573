// Checkdate - Validate a Gregorian date, from an ISO formatted date string (YYYY-MM-DD) - as per PHP function checkdate()
// @param can pass in either:
// bool TRUE to validate a single field
// OR
// a string of 3 DOM ID's in the sequence Year, Month, Day e.g. #form_user_dob_year #form_user_dob_month #form_user_dob_day
// Note: only assign the validation rule to 1 of the 3 fields. e.g "user[dob][Year]": { required:true, checkdate: ['#form_user_dob_year', '#form_user_dob_month', '#form_user_dob_day'] },

jQuery.validator.addMethod("checkdate", function (value, element, param) {
    console.log("Running checkdate");
    // if validating 3 fields, replace the validating 'value' with the 3 field values, in ISO format YYYY-MM-DD

    if (param && param !== true) {
        value = jQuery(param[0]).val() + '-' + jQuery(param[1]).val() + '-' + jQuery(param[2]).val();
        console.log(value);
    }

    // validate against jquery validator's dateISO: check

    // http://docs.jquery.com/Plugins/Validation/Methods/dateISO

    var validDate = /^\d{4}[\/-]\d{1,2}[\/-]\d{1,2}$/.test(value);
    if (validDate) {
        var dateArray = value.split('-');
        var year = dateArray[0];
        var month = dateArray[1];
        var day = dateArray[2];

        // PHP.js checkdate validation

        // http://kevin.vanzonneveld.net

        // + original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // + improved by: Pyerre
        // * example 1: checkdate(12, 31, 2000);
        // * returns 1: true
        // * example 2: checkdate(2, 29, 2001);
        // * returns 2: false
        // * example 3: checkdate(03, 31, 2008);
        // * returns 3: true
        // * example 4: checkdate(1, 390, 2000);
        // * returns 4: false
        var myDate = new Date();
        myDate.setFullYear(year, (month - 1), day);
        var success = month >= 1 && month <= 12 && year >= 1 && year <= 32767 && ((myDate.getMonth() + 1) == month && day < 32);
        
        // populate the #dobber field with an ISO formatted date
        if ( success && jQuery('#dobber').length ) {
            console.log("Setting #dobber to " + value);
            jQuery('#dobber').val(value);
        }
        return this.optional(element) || success;
    }
}, jQuery.validator.messages.checkdate);




//// validate a phone number. Strips spaces, allows optional leading + character, the rest are 0-9.
//jQuery.validator.addMethod("validphone", function(phone_number, element) {
//    phone_number = phone_number.replace(/\s+/g, ""); 
//	var pattern = /^\+{0,1}\d{1,}$/; 
//	// ^\+{0,1}
//	var success = phone_number.match(pattern);
//	return this.optional(element) || success;
//// }, jQuery.validator.messages.validphone);
//}, 'Please enter a valid phone number');

// validate a Date of Birth - checks it's in the future
jQuery.validator.addMethod("dobber", function (strDateOfBirth, element) {
    console.log("Running dobber");
    
    var success;
    var dateOfBirth = moment(strDateOfBirth, 'YYYY-MM-DD');
    var nowDate = moment();
    var years;

    var validDateFormat = /^\d{1,2}[\//]\d{1,2}[\//]\d{4}$/.test(strDateOfBirth);

    if (validDateFormat !== true) {
        success = false;
    } else {
        var validDate = dateOfBirth.isValid();

        if (validDate === true) {
            /* years = nowDate.diff(dateOfBirth, 'years');
             if (years < 0){
             success = false;
             }
             else {
             success = true;
             }
             */
            success = dateOfBirth.isBefore(nowDate, 'day');
        } else {
            success = false;
        }
    }
    // console.log('Is optional: '+this.optional(element));
    return this.optional(element) || success;
}, 'Please enter a valid date of birth');

jQuery.validator.addMethod("validThreepartDoB", function (value, element, param) {
    var success;
    var yyyy = jQuery(param[0]).val();
    var mm = jQuery(param[1]).val();
    var dd = jQuery(param[2]).val();
    var fulldate = jQuery(param[3]);
//    console.log("Running validThreepartDoB");
    
    var strDateOfBirth = yyyy + '-' + mm + '-' + dd;
//    console.log(strDateOfBirth);

    if (dd.length && mm.length && yyyy.length){
   
        var validDateFormat = /^\d{4}[\-]\d{1,2}[\-]\d{1,2}$/.test(strDateOfBirth);


        if (validDateFormat !== true) {
            success = false;
        } else {
            jQuery(fulldate).val(strDateOfBirth);

            var dtDob = moment(strDateOfBirth, 'YYYY-MM-DD');
            var nowDate = moment();

            var validDate = dtDob.isValid();

            if (validDate === true) {
                /* years = nowDate.diff(dateOfBirth, 'years');
                 if (years < 0){
                 success = false;
                 }
                 else {
                 success = true;
                 }
                 */
                success = dtDob.isBefore(nowDate, 'day');
            } else {
                success = false;
            }
        }
    } else {
        jQuery(fulldate).val(0);
        success = false;
    }
    return this.optional(element) || success;

}, jQuery.validator.messages.validThreepartDoB);



// validate a phone number. Strips spaces, allows optional leading + character, 
// the rest are 0-9, round brackets or a dash.
jQuery.validator.addMethod("validphone", function (phone_number, element) {
    phone_number = phone_number.replace(/\s+/g, "");
    var pattern = /^\+{0,1}[0-9()\-]{1,}$/;
    // ^\+{0,1}
    var success = phone_number.match(pattern);
    return this.optional(element) || success;
// }, jQuery.validator.messages.validphone);
}, 'Please enter a valid phone number');

jQuery.validator.addMethod("unique", function (value, element, params) {
    var prefix = params;
    var selector = jQuery.validator.format("[name!='{0}'][unique='{1}']", element.name, prefix);
    // var matches = new Array();
    var matches = [];
    $(selector).each(function (index, item) {
        if ($(item).val() !== '' && value == $(item).val()) {
            matches.push(item);
        }
    });

    return matches.length == 0;
}, "Value is not unique.");

jQuery.validator.classRuleSettings.unique = {
    unique: true
};